import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MeetAndGreetForm from './atoms/MeetAndGreetForm';
import Navbar from './atoms/Navbar';
import ROUTES from './constants/routes';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import { InterestFormSubmissionsProvider } from './context/InterestFormSubmissionContext';
import { MeetAndGreetFormSubmissionsProvider } from './context/MeetAndGreetSubmissionContext';
import { TasksProvider } from './context/TasksContext';
import About from './pages/About';
import Admin from './pages/Admin';
import Events from './pages/Events';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <AuthProvider>
        <AppProvider>
          <TasksProvider>
            <InterestFormSubmissionsProvider>
              <MeetAndGreetFormSubmissionsProvider>
                <Router>
                  <CssBaseline />
                  <Navbar />

                  <Routes>
                    <Route path={ROUTES.HOME} element={<Home />} />
                    <Route path={ROUTES.ABOUT} element={<About />} />
                    <Route path={ROUTES.EVENTS} element={<Events />} />
                    <Route
                      path={ROUTES.GETINVOLVED}
                      element={<MeetAndGreetForm />}
                    />
                    <Route path={ROUTES.ADMIN} element={<Admin />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>

                  <Toaster
                    position="bottom-left"
                    toastOptions={{
                      duration: 6000,
                    }}
                  />
                </Router>
              </MeetAndGreetFormSubmissionsProvider>
            </InterestFormSubmissionsProvider>
          </TasksProvider>
        </AppProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
