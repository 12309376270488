import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { logEvent } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';
import toast from 'react-hot-toast';

import InterestForm from './InterestForm';

import { STRINGS } from '../constants/strings';
import { analytics } from '../firebaseConfig';

interface InputWithButtonProps {
  focusEmailInput: boolean;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  focusEmailInput,
}) => {
  const [email, setEmail] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const emailInputRef = React.useRef<HTMLInputElement>(null);

  const functions = getFunctions();

  const theme = useTheme();

  useEffect(() => {
    if (focusEmailInput && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [focusEmailInput]);

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setIsSubmitting(true);

    try {
      const addEmailToMailchimp = httpsCallable(
        functions,
        'addEmailToMailchimp',
      );
      await addEmailToMailchimp({ email: email.trim() });
      toast.success(STRINGS.mailchimpSubmitToastSuccess);
      setIsDialogOpen(true);
      logEvent(analytics, 'mailchimp_signup');
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as Record<string, any>;

      const errorDetails = typeof err?.details === 'object' ? err.details : {};
      const errorMessage =
        typeof errorDetails?.title === 'string'
          ? errorDetails.title
          : typeof err?.message === 'string'
            ? err.message
            : 'Something went wrong. Please try again.';

      if (errorMessage.includes('Member Exists')) {
        toast.error(STRINGS.mailchimpAlreadySubscribedError);
      } else if (errorMessage.includes('Invalid Resource')) {
        toast.error(STRINGS.mailchimpInvalidEmailError);
      } else {
        toast.error(errorMessage);
      }
    }

    setIsSubmitting(false);
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleEmailSubmit();
    }
  };

  const closeInterestForm = () => {
    setIsDialogOpen(false);
    setEmail('');
  };

  const inputMinWidth =
    window.innerWidth < theme.breakpoints.values.sm ? '150px' : '200px';

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <input
            data-testid="email-input"
            style={{
              border: 'none',
              borderBottomLeftRadius: '20px',
              borderTopLeftRadius: '20px',
              flex: 1,
              marginRight: '-1px',
              minWidth: inputMinWidth,
              outline: 'none',
              padding: '10px 20px',
            }}
            type="text"
            placeholder={STRINGS.callToActionInputPlaceholder}
            value={email}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            ref={emailInputRef}
          />
          <button
            data-testid="submit-button"
            style={{
              alignItems: 'center',
              backgroundColor: '#92D5E6',
              border: 'none',
              borderBottomRightRadius: '20px',
              borderTopRightRadius: '20px',
              color: theme.palette.primary.contrastText,
              cursor: isSubmitting ? 'default' : 'pointer',
              display: 'flex',
              fontFamily: "'Bungee', sans-serif",
              fontSize: '0.875rem',
              fontWeight: 500,
              justifyContent: 'center',
              padding: '10px 20px',
              textTransform: 'none',
            }}
            onClick={handleEmailSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? <>Loading...</> : STRINGS.callToActionButtonText}
          </button>
        </div>
      </div>
      <Dialog open={isDialogOpen} onClose={closeInterestForm}>
        <InterestForm closeInterestForm={closeInterestForm} email={email} />
      </Dialog>
    </>
  );
};

export default InputWithButton;
