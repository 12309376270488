import React from 'react';

import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={{
      '.recharts-legend-wrapper': {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    }}
  />
);

export default GlobalStyles;
