import React, { createContext, ReactNode, useContext } from 'react';

import {
  collection,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';

import { firestore } from '../firebaseConfig';
import { addNewAnonymousItem } from '../utils/firebaseUtils';

export interface InterestFormSubmission {
  adminComments?: string;
  checked?: boolean;
  comments: string;
  createdAt: Timestamp;
  email: string;
  formType: string;
  id: string;
  interests: InterestOption[];
  updatedAt?: Timestamp;
  updatedBy?: string;
}

export interface InterestFormContent {
  comments: string;
  email: string;
  interests: InterestOption[];
}

export interface InterestOption {
  title: string;
  type: string;
}

interface InterestFormSubmissionsContextType {
  addInterestFormSubmission: (formContent: InterestFormContent) => void;
  getInterestFormSubmissions: () => Promise<InterestFormSubmission[]>;
  updateInterestFormSubmission: (
    id: string,
    updateObj: Partial<InterestFormSubmission>,
  ) => Promise<void>;
}

const InterestFormSubmissionsContext =
  createContext<InterestFormSubmissionsContextType>({
    addInterestFormSubmission: async () => {},
    getInterestFormSubmissions: async () => [],
    updateInterestFormSubmission: async () => {},
  });

export const useInterestFormSubmissions = () =>
  useContext(InterestFormSubmissionsContext);

interface InterestFormSubmissionsProviderProps {
  children: ReactNode;
}

export const InterestFormSubmissionsProvider = ({
  children,
}: InterestFormSubmissionsProviderProps) => {
  const addInterestFormSubmission = async (
    formContent: InterestFormContent,
  ) => {
    await addNewAnonymousItem('interestFormSubmissions', formContent);
  };

  const getInterestFormSubmissions = async (): Promise<
    InterestFormSubmission[]
  > => {
    const submissionsCol = collection(firestore, 'interestFormSubmissions');
    const submissionSnapshot = await getDocs(submissionsCol);
    return submissionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as InterestFormSubmission[];
  };

  const updateInterestFormSubmission = async (
    id: string,
    updateObj: Partial<InterestFormSubmission>,
  ) => {
    const submissionDoc = doc(firestore, 'interestFormSubmissions', id);
    await updateDoc(submissionDoc, updateObj);
  };

  return (
    <InterestFormSubmissionsContext.Provider
      value={{
        addInterestFormSubmission,
        getInterestFormSubmissions,
        updateInterestFormSubmission,
      }}
    >
      {children}
    </InterestFormSubmissionsContext.Provider>
  );
};
