import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Avatar,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Link,
} from '@mui/material';

import ceramicsImg from '../../assets/toolIcons/ceramics.png';
import electronicsImg from '../../assets/toolIcons/electronics.png';
import printersImg from '../../assets/toolIcons/printer.png';
import textileImg from '../../assets/toolIcons/textiles.png';
import woodworkingImg from '../../assets/toolIcons/woodworking.png';
import { STRINGS } from '../../constants/strings';

interface AboutUsContentBlockProps {
  children: React.ReactNode;
  isMobile: boolean;
  title: string;
}

const AboutUsContentBlock = ({
  title,
  children,
  isMobile,
}: AboutUsContentBlockProps) => {
  if (isMobile) {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

interface AboutUsProps {
  handleEmailClick: () => void;
}

const AboutUs = ({ handleEmailClick }: AboutUsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ mb: 7 }}>
      <AboutUsTexts />

      <Box
        sx={{
          display: isMobile ? 'block' : 'flex',
          gap: 2,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <AboutUsContentBlock
          title={STRINGS.toolsSectionTitle}
          isMobile={isMobile}
        >
          <ActivityList />
        </AboutUsContentBlock>

        <AboutUsContentBlock title="Where are you located?" isMobile={isMobile}>
          <Location handleEmailClick={handleEmailClick} />
        </AboutUsContentBlock>
      </Box>
    </Box>
  );
};

interface LocationProps {
  handleEmailClick: () => void;
}

const Location = ({ handleEmailClick }: LocationProps) => {
  return (
    <>
      <Typography paragraph>
        Urm…nowhere, YET. We are currently in the process of finding a permanent
        location, but we’re leaning toward <strong>North Brooklyn</strong>{' '}
        (Williamsburgish/Bushwick).
      </Typography>

      <Typography paragraph>
        If you&apos;re excited about joining the founding community of Brooklyn
        Spark, we&apos;d love to keep you in the loop!{' '}
        <Link
          component="button"
          onClick={handleEmailClick}
          color="secondary.main"
        >
          Leave your email
        </Link>{' '}
        to get updates on our journey, including when we find our home and how
        you can get involved.
      </Typography>
      <Typography
        paragraph
        sx={{
          mb: 0,
        }}
      >
        Don&apos;t miss out on being part of our story from the very beginning.
      </Typography>
    </>
  );
};

const activities = [
  {
    icon: (
      <img
        src={woodworkingImg}
        alt="Woodworking"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Woodworking',
  },
  {
    icon: (
      <img
        src={electronicsImg}
        alt="Electronics"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Electronics',
  },
  {
    icon: (
      <img
        src={printersImg}
        alt="3d printers & Laser cutters"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: '3d printers & Laser cutters',
  },
  {
    icon: (
      <img
        src={textileImg}
        alt="Textile arts"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Textile arts',
  },
  {
    icon: (
      <img
        src={ceramicsImg}
        alt="Ceramics"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Ceramics',
  },
];

const ActivityList = () => {
  const theme = useTheme();

  const itemSx = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: theme.spacing(1),
    width: 100,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        pt: 3,
      }}
    >
      {activities.map((activity, index) => (
        <Box key={index} sx={itemSx}>
          <Avatar
            sx={{
              bgcolor: 'background.default',
              height: 65,
              width: 65,
            }}
          >
            {activity.icon}
          </Avatar>
          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: 1.3,
              mt: 0.8,
              textAlign: 'center',
            }}
          >
            {activity.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const AboutUsTexts = () => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>

      <Typography paragraph>
        We are a{' '}
        <strong>
          volunteer-run organization of artists and makers driven by curiosity,
          invention, and skill sharing
        </strong>
        . Whether you’re a seasoned builder, inventor, crafty one, or a total
        newbie ready to get your hands dirty, our community is committed to
        fostering an encouraging environment in which you feel welcome.
      </Typography>

      <Typography variant="h4" gutterBottom>
        {'Who is this for?'}
      </Typography>
      <Typography paragraph>
        Brooklyn Spark invites{' '}
        <strong>individuals from all backgrounds and skill levels</strong> to
        come together to learn, create, and explore. We believe in the power of
        interaction, the value of lifelong learning, and the importance of
        providing opportunities for individuals to expand their knowledge and
        abilities in a supportive space.
      </Typography>

      <Typography variant="h4" gutterBottom>
        What will the space include?
      </Typography>
      <Typography paragraph>
        As a makerspace, Brooklyn Spark will provide members with the{' '}
        <strong>tools</strong> and <strong>square footage</strong> to create
        comfortably; as well as <strong>workshops, classes</strong>, and of
        course: the possibility of <strong>friendship, collaboration</strong>,
        and meeting <strong>like-minded individuals</strong>.
      </Typography>
    </Box>
  );
};

export default AboutUs;
