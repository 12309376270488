import * as React from 'react';

import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';
import { Badge, Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';

import { ReactComponent as DiscordSVG } from '../assets/discord.svg';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import ROUTES from '../constants/routes';
import { STRINGS } from '../constants/strings';

interface NavbarProps {}

interface Page {
  link: string;
  title: string;
}

const DiscordIcon = styled(DiscordSVG)(({ theme }) => ({
  fill: theme.palette.secondary.main,
  height: 24,
  width: 24,
}));

const LogoIcon = styled(LogoSVG)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.palette.secondary.main,
  height: 45,
  stroke: theme.palette.secondary.main,
  strokeWidth: 15,
  width: 45,
}));

const pages = [
  { badge: null, link: ROUTES.ABOUT, title: 'The Team' },
  {
    // badge: ' ',
    badge: null,
    link: ROUTES.EVENTS,
    title: 'Events',
  },
  {
    badge: null,
    link: ROUTES.GETINVOLVED,
    title: 'Get Involved',
  },
];

const Navbar: React.FC<NavbarProps> = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const navigate = useNavigate();

  const handleNavMenuClick = (page: Page) => {
    navigate(page.link);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ bgcolor: 'primary.main', boxShadow: 'none' }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              ':hover': {
                '& a': {
                  color: 'secondary.main',
                },
                '& svg': {
                  fill: 'secondary.main',
                  stroke: 'secondary.main',
                },
              },
              cursor: 'pointer',
              display: { md: 'flex', xs: 'none' },
            }}
            onClick={() =>
              handleNavMenuClick({ link: ROUTES.HOME, title: 'Home' })
            }
          >
            <LogoIcon
              sx={{
                mr: 1.2,
              }}
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                color: '#black',
                cursor: 'pointer',
                fontFamily: "'Bungee', sans-serif",
                fontSize: '1.8rem',
                letterSpacing: '.08rem',
                lineHeight: 0.9,
                mt: 2.3,
                textDecoration: 'none',
                textShadow: '2.2px 2.2px 0px white',
              }}
            >
              {STRINGS.homePageTitle.toUpperCase()}
            </Typography>
          </Box>

          <Box sx={{ display: { md: 'none', xs: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              keepMounted
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { md: 'none', xs: 'block' },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleNavMenuClick({ link: ROUTES.HOME, title: 'Home' });
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Home</Typography>
              </MenuItem>

              {pages.map((page) => (
                <MenuItem
                  onClick={() => {
                    handleNavMenuClick(page);
                    handleCloseNavMenu();
                  }}
                  key={page.title}
                >
                  {page.badge ? (
                    <Badge
                      badgeContent={page.badge}
                      color="secondary"
                      variant="dot"
                    >
                      <Typography textAlign="center">{page.title}</Typography>
                    </Badge>
                  ) : (
                    <Typography textAlign="center">{page.title}</Typography>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              ':hover': {
                '& a': {
                  color: 'secondary.main',
                },
                '& svg': {
                  fill: 'secondary.main',
                  stroke: 'secondary.main',
                },
              },
              alignItems: 'center',
              cursor: 'pointer',
              display: { md: 'none', xs: 'flex' },
              flexGrow: 1,
              justifyContent: 'center',
            }}
            onClick={() =>
              handleNavMenuClick({ link: ROUTES.HOME, title: 'Home' })
            }
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                color: 'black',
                cursor: 'pointer',
                display: { md: 'none', sm: 'flex' },
                fontFamily: "'Bungee', sans-serif",
                fontSize: '4vw',
                letterSpacing: '.08rem',
                lineHeight: 0.9,
                textDecoration: 'none',
                textShadow: '2.2px 2.2px 0px white',
              }}
            >
              {STRINGS.homePageTitle.toUpperCase()}
            </Typography>
          </Box>

          <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1, ml: 3 }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => {
                  handleNavMenuClick(page);
                  handleCloseNavMenu();
                }}
                sx={{ color: 'black', display: 'block', mr: 1, mt: 3.3 }}
              >
                {page.badge ? (
                  <Badge
                    badgeContent={page.badge}
                    variant="dot"
                    color="secondary"
                  >
                    {page.title}
                  </Badge>
                ) : (
                  page.title
                )}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              color="secondary"
              aria-label="Discord"
              component="a"
              href={STRINGS.discordLink}
              target="_blank"
            >
              <DiscordIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="Instagram"
              component="a"
              href={STRINGS.instagramLink}
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
