import React, { useEffect } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CustomPieCharts from '../components/Admin/CustomPieCharts';
import FormSumissions from '../components/Admin/FormSubmissions';
import ROUTES from '../constants/routes';
import { useAuth } from '../context/AuthContext';

const Admin: React.FC = () => {
  const { signInWithGoogle, logout, currentUser, loading, error } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && currentUser) {
      navigate(ROUTES.ADMIN);
    }
  }, [currentUser, loading, navigate]);

  const handleLogin = async () => {
    try {
      await signInWithGoogle();
      // The navigation is handled in the useEffect hook based on the currentUser state
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(ROUTES.ADMIN);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Container>
      {currentUser ? (
        <Box
          sx={{
            mb: 5,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2.5,
              mt: 2.5,
            }}
          >
            <Typography variant="h4" component="h1">
              Admin Area
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
              endIcon={<LogoutIcon />}
            >
              Sign Out
            </Button>
          </Box>

          <CustomPieCharts />
          <FormSumissions />
        </Box>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '60vh',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Admin Login
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Sign in with Google
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Admin;
