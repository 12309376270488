import React, { useRef } from 'react';

const VideoPlayer: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <video
      ref={videoRef}
      src="/snail-animation-2.mp4"
      autoPlay
      muted
      playsInline
      style={{ height: 'auto', maxHeight: '600px', width: '100%' }}
    />
  );
};

export default VideoPlayer;
