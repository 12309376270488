import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { orderBy, Timestamp } from 'firebase/firestore';

import { useAuth } from './AuthContext';

import {
  addNewItem,
  deleteItem,
  fetchItems,
  updateItem,
} from '../utils/firebaseUtils';

export interface Task {
  completed: boolean;
  completedAt?: Timestamp;
  createdAt: Timestamp;
  id: string;
  title: string;
  userId: string;
}

interface TasksContextType {
  addTask: (taskTitle: string) => void;
  deleteTask: (taskId: string) => void;
  loading: boolean;
  tasks: Task[];
  updateTask: (taskId: string, task: Partial<Task>) => void;
}

const TasksContext = createContext<TasksContextType>({
  addTask: async () => {},
  deleteTask: async () => {},
  loading: true,
  tasks: [],
  updateTask: async () => {},
});

export const useTasks = () => useContext(TasksContext);

interface TasksProviderProps {
  children: ReactNode;
}

export const TasksProvider = ({ children }: TasksProviderProps) => {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTasks = useCallback(async () => {
    if (currentUser) {
      const orderClause = orderBy('completed');

      fetchItems('tasks', currentUser.uid, setTasks, orderClause).then(() =>
        setLoading(false),
      );
    }
  }, [currentUser]);

  const addTask = async (taskTitle: string) => {
    const newTask: Omit<Task, 'id' | 'createdAt' | 'userId'> = {
      completed: false,
      title: taskTitle,
    };

    await addNewItem('tasks', newTask, currentUser?.uid, setTasks);
  };

  const updateTask = async (taskId: string, updates: Partial<Task>) => {
    await updateItem('tasks', taskId, updates, currentUser?.uid, setTasks);
  };

  const deleteTask = async (taskId: string) => {
    await deleteItem('tasks', taskId, currentUser?.uid, setTasks);
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <TasksContext.Provider
      value={{ addTask, deleteTask, loading, tasks, updateTask }}
    >
      {children}
    </TasksContext.Provider>
  );
};
