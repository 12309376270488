import React, { createContext, ReactNode, useContext } from 'react';

import {
  doc,
  collection,
  getDocs,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';

import { HelpOption } from '../atoms/MeetAndGreetForm';
import { firestore } from '../firebaseConfig';
import { addNewAnonymousItem } from '../utils/firebaseUtils';

export interface MeetAndGreetSubmission {
  about: string;
  adminComments?: string;
  checked?: boolean;
  createdAt: Timestamp;
  email: string;
  extraInfo: string;
  formType: string;
  helpOptions: HelpOption[];
  id: string;
  name: string;
  phoneNumber: string;
  updatedAt?: Timestamp;
  updatedBy?: string;
}

export interface MeetAndGreetFormContent {
  about: string;
  email: string;
  extraInfo: string;
  helpOptions: HelpOption[];
  name: string;
  phoneNumber: string;
}

interface MeetAndGreetFormSubmissionsContextType {
  addMeetAndGreetFormSubmission: (formContent: MeetAndGreetFormContent) => void;
  getMeetAndGreetSubmissions: () => Promise<MeetAndGreetSubmission[]>;
  updateMeetAndGreetFormSubmission: (
    id: string,
    updateObj: Partial<MeetAndGreetSubmission>,
  ) => Promise<void>;
}

const MeetAndGreetFormSubmissionsContext =
  createContext<MeetAndGreetFormSubmissionsContextType>({
    addMeetAndGreetFormSubmission: async () => {},
    getMeetAndGreetSubmissions: async () => [],
    updateMeetAndGreetFormSubmission: async () => {},
  });

export const useMeetAndGreetFormSubmissions = () =>
  useContext(MeetAndGreetFormSubmissionsContext);

interface MeetAndGreetFormSubmissionsProviderProps {
  children: ReactNode;
}

export const MeetAndGreetFormSubmissionsProvider = ({
  children,
}: MeetAndGreetFormSubmissionsProviderProps) => {
  const addMeetAndGreetFormSubmission = async (
    formContent: MeetAndGreetFormContent,
  ) => {
    await addNewAnonymousItem('meetAndGreetFormSubmissions', formContent);
  };

  const getMeetAndGreetSubmissions = async (): Promise<
    MeetAndGreetSubmission[]
  > => {
    const submissionsCol = collection(firestore, 'meetAndGreetFormSubmissions');
    const submissionsSnapshot = await getDocs(submissionsCol);

    const submissionsList: MeetAndGreetSubmission[] = [];
    submissionsSnapshot.forEach((doc) => {
      const submission = doc.data() as MeetAndGreetSubmission;
      submission.id = doc.id;
      submissionsList.push(submission);
    });

    return submissionsList;
  };

  const updateMeetAndGreetFormSubmission = async (
    id: string,
    updateObj: Partial<MeetAndGreetSubmission>,
  ) => {
    const submissionDoc = doc(firestore, 'meetAndGreetFormSubmissions', id);
    await updateDoc(submissionDoc, updateObj);
  };

  return (
    <MeetAndGreetFormSubmissionsContext.Provider
      value={{
        addMeetAndGreetFormSubmission,
        getMeetAndGreetSubmissions,
        updateMeetAndGreetFormSubmission,
      }}
    >
      {children}
    </MeetAndGreetFormSubmissionsContext.Provider>
  );
};
