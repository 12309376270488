import React from 'react';

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  Box,
} from '@mui/material';

import meetAndGreetImage from '../assets/meetngreet.png';

interface Event {
  date: string;
  description: string;
  imageUrl: string;
  title: string;
  url: string;
}

interface EventsProps {}

const Events: React.FC<EventsProps> = () => {
  const events: Event[] = [
    {
      date: 'June 9, 2024',
      description: `Join us for our first event - a Meet & Greet! Come meet other makers, creatives, and the team behind Brooklyn Spark. As always, beginners and dabblers welcome. We'll bring the drinks and snacks!`,
      imageUrl: meetAndGreetImage,
      title: 'Meet & Greet',
      url: 'https://www.instagram.com/stories/highlights/18028903180935974/',
    },
  ];

  const isEventPast = (date: string) => {
    const eventDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return eventDate < today;
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Upcoming Events
      </Typography>
      <Grid container spacing={4}>
        {events.map((event, index) => {
          const eventPast = isEventPast(event.date);

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  position: 'relative',
                }}
              >
                {eventPast && (
                  <Box
                    sx={{
                      bgcolor: 'rgba(0, 0, 0, 0.608)',
                      color: 'white',
                      fontSize: '1.5rem',
                      fontWeight: '400',
                      left: 0,
                      p: 1,
                      position: 'absolute',
                      textAlign: 'center',
                      top: 0,
                      width: '100%',
                      zIndex: 1,
                    }}
                  >
                    Event Ended
                  </Box>
                )}
                <CardActionArea
                  href={event.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardMedia
                    component="img"
                    image={event.imageUrl}
                    alt={event.title}
                    sx={{
                      objectFit: 'cover',
                    }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {event.title}
                    </Typography>

                    <Typography variant="body1" color="text.primary">
                      {event.description}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {event.date}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Events;
