import { FirebaseError } from 'firebase/app';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import toast from 'react-hot-toast';

import { getFirebaseErrorMessage } from './firebaseErrors';

import { firestore } from '../firebaseConfig';

// Define a generic type for items that includes a minimum shape
interface Item {
  createdAt: Timestamp;
  id: string;
}

export async function addNewAnonymousItem<T extends Item>(
  collectionName: string,
  newItemData: Omit<T, 'id' | 'createdAt' | 'userId'>,
): Promise<void> {
  // Generate a temporary local ID and add default fields
  const newItem: T = {
    ...(newItemData as T),
    createdAt: Timestamp.now(),
  } as T;
  try {
    const colRef = collection(firestore, collectionName);
    await addDoc(colRef, newItem);
  } catch (error) {
    console.error('Error saving new item: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error('Failed to save');
    }
  }
}

export async function fetchAnonymousItems<T>(
  collectionName: string,
  setItems: React.Dispatch<React.SetStateAction<T[]>>,
  orderClause: QueryConstraint = orderBy('createdAt', 'desc'),
): Promise<void> {
  try {
    const colRef = collection(firestore, collectionName);

    const q = query(colRef, orderClause);
    const querySnapshot = await getDocs(q);

    const itemList: T[] = [];
    querySnapshot.forEach((doc) => {
      itemList.push({ id: doc.id, ...doc.data() } as T);
    });
    setItems(itemList);
  } catch (error) {
    console.error('Error fetching items: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error(`Failed to fetch items from ${collectionName}`);
    }
  }
}

// Generic function to add a new item
export async function addNewItem<T extends Item>(
  collectionName: string,
  newItemData: Omit<T, 'id' | 'createdAt' | 'userId'>,
  currentUserUid: string | undefined,
  setItems: React.Dispatch<React.SetStateAction<T[]>>,
): Promise<void> {
  if (!currentUserUid) return;

  // Generate a temporary local ID and add default fields
  const tempId = Date.now().toString();
  const newItem: T = {
    ...(newItemData as T),
    createdAt: Timestamp.now(),
    userId: currentUserUid,
  } as T;
  // Optimistically add the item to local state with the temporary ID
  setItems((prevItems) => [...prevItems, { ...newItem, id: tempId }]);

  try {
    const colRef = collection(firestore, collectionName);
    const newDoc = await addDoc(colRef, newItem);

    // Update local state with the Firebase-generated ID
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === tempId ? { ...item, id: newDoc.id } : item,
      ),
    );
  } catch (error) {
    console.error('Error creating new item: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error('Failed to create item');
    }

    // Remove the item from the local state if creation was unsuccessful
    setItems((prevItems) => prevItems.filter((item) => item.id !== tempId));
  }
}

export async function updateItem<T>(
  collectionName: string,
  itemId: string,
  updatedProperties: Partial<T>,
  currentUserUid: string | undefined,
  setItems: React.Dispatch<React.SetStateAction<T[]>>,
): Promise<void> {
  if (!currentUserUid) return;
  // Store the original item in case we need to revert the state
  let originalItem: T | null = null;

  setItems((prevItems) => {
    const newItems = prevItems.map((item) => {
      if ((item as Item).id === itemId) {
        originalItem = { ...item }; // Make a copy of the original item
        return { ...item, ...updatedProperties }; // Apply the updates optimistically
      }
      return item;
    });
    return newItems;
  });

  try {
    const itemRef = doc(firestore, collectionName, itemId);
    await updateDoc(itemRef, updatedProperties);
  } catch (error) {
    console.error('Error updating item: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error('Failed to update item.');
    }

    // Revert to the original item if the update fails
    if (originalItem) {
      setItems((prevItems) =>
        prevItems.map((item) =>
          (item as Item).id === itemId ? originalItem! : item,
        ),
      );
    }
  }
}

export async function deleteItem<T>(
  collectionName: string,
  itemId: string,
  currentUserUid: string | undefined,
  setItems: React.Dispatch<React.SetStateAction<T[]>>,
): Promise<void> {
  if (!currentUserUid) return;

  let deletedItem: T | null = null;

  // Store the item to be deleted in case we need to add it back
  setItems((prevItems) => {
    const itemIndex = prevItems.findIndex(
      (item) => (item as Item).id === itemId,
    );
    if (itemIndex !== -1) {
      deletedItem = prevItems[itemIndex];
      return [
        ...prevItems.slice(0, itemIndex),
        ...prevItems.slice(itemIndex + 1),
      ];
    }
    return prevItems;
  });

  try {
    const itemRef = doc(firestore, collectionName, itemId);
    await deleteDoc(itemRef);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error('Error deleting item: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error('Failed to delete item.');
    }

    // Add the item back to local state if deletion fails
    if (deletedItem) {
      setItems((prevItems) => [...prevItems, deletedItem!]);
    }
  }
}

export async function fetchItems<T>(
  collectionName: string,
  userUid: string,
  setItems: React.Dispatch<React.SetStateAction<T[]>>,
  orderClause: QueryConstraint = orderBy('createdAt', 'desc'),
): Promise<void> {
  try {
    const colRef = collection(firestore, collectionName);

    const q = query(colRef, where('userId', '==', userUid), orderClause);
    const querySnapshot = await getDocs(q);

    const itemList: T[] = [];
    querySnapshot.forEach((doc) => {
      itemList.push({ id: doc.id, ...doc.data() } as T);
    });
    setItems(itemList);
  } catch (error) {
    console.error('Error fetching items: ', error);

    if (error instanceof FirebaseError) {
      toast.error(getFirebaseErrorMessage(error.code));
    } else {
      toast.error(`Failed to fetch items from ${collectionName}`);
    }
  }
}
