import { createTheme } from '@mui/material/styles';

const headerFont = {
  fontFamily: "'Bungee', sans-serif",
};

const bodyFont = {
  fontFamily: "'Roboto', sans-serif",
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: '#F8F8F8',
      paper: '#fff',
    },
    mode: 'light',
    primary: {
      contrastText: '#1A1A18',
      main: '#FFC43D',
    },
    secondary: {
      contrastText: '#1A1A18',
      main: '#E7553D',
    },
    text: {
      disabled: '#B4AB9E',
      primary: '#1A1A18',
      secondary: '#514633',
    },
  },
  typography: {
    ...bodyFont,
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'none',
      ...headerFont,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      ...headerFont,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 500,
      ...headerFont,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      ...headerFont,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      ...headerFont,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      ...headerFont,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
      ...headerFont,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
});

export default theme;
