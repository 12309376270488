import React, { useCallback, useEffect, useState } from 'react';

import {
  Typography,
  Card,
  CardContent,
  Checkbox,
  TextField,
  FormControlLabel,
  Box,
  Chip,
  InputAdornment,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';

interface FormRowContent {
  adminComments?: string;
  checked?: boolean;
  comments?: string;
  createdAt: Timestamp;
  email: string;
  formType: string;
  id: string;
  options: {
    title: string;
    type: string;
  }[];
  updatedBy?: string;
}

interface UserFormSubmissionCardProps {
  onAdminUpdate: (
    id: string,
    formType: string,
    updateObj: Partial<FormRowContent>,
  ) => Promise<void>;
  submission: FormRowContent;
}

const UserFormSubmissionCard: React.FC<UserFormSubmissionCardProps> = ({
  submission,
  onAdminUpdate,
}) => {
  const [comment, setComment] = useState(submission.adminComments);
  const [isSaved, setIsSaved] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>(
    undefined,
  );

  const saveComment = useCallback(() => {
    if (comment !== submission.adminComments) {
      console.log('Saving comment');
      onAdminUpdate(submission.id, submission.formType, {
        adminComments: comment,
      })
        .then(() => setIsSaved(true))
        .catch((error) => console.error('Error saving comment:', error));
    }
  }, [comment, submission, onAdminUpdate]);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      saveComment();
    }, 1000);

    setDebounceTimeout(timeout as unknown as number);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, saveComment]);

  useEffect(() => {
    if (isSaved) {
      const timeout = setTimeout(() => setIsSaved(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [isSaved]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleContactedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onAdminUpdate(submission.id, submission.formType, {
      checked: event.target.checked,
    });
  };

  return (
    <Card sx={{ marginBottom: 2 }} variant="outlined">
      <CardContent>
        <Typography variant="h6">{submission.email}</Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Submitted on:</strong>{' '}
          {submission.createdAt.toDate().toLocaleDateString()} |{' '}
          <strong>Form:</strong>{' '}
          {submission.formType === 'interest'
            ? 'Email Subscription Form'
            : 'Get Involved Form'}
        </Typography>

        <Box sx={{ marginTop: 1 }}>
          {submission?.options &&
            submission.options.map((interest) => (
              <Chip
                key={interest.title}
                label={interest.title}
                sx={{ marginBottom: 0.5, marginRight: 0.5 }}
                size="small"
              />
            ))}
        </Box>

        {submission.comments && (
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {submission.comments}
          </Typography>
        )}

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <TextField
            label="Admin Comments"
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={comment}
            onChange={handleCommentChange}
            size="small"
            InputProps={{
              endAdornment: isSaved ? (
                <InputAdornment position="end">
                  <Typography variant="body2" color="green">
                    Saved
                  </Typography>
                </InputAdornment>
              ) : null,
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={submission.checked}
                onChange={handleContactedChange}
                color="primary"
              />
            }
            label="Contacted"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserFormSubmissionCard;
