import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';

import { auth } from '../firebaseConfig';

interface AuthContextType {
  currentUser: User | null;
  error: string | null;
  loading: boolean;
  logout: () => void;
  signInWithGoogle: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  error: null,
  loading: true,
  logout: () => {},
  signInWithGoogle: async () => {},
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        const isAdmin = idTokenResult.claims.admin;
        if (isAdmin) {
          setCurrentUser(user);
          setError(null);
        } else {
          await signOut(auth);
          setError('Access denied. Your email is not whitelisted.');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setError(null);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idTokenResult = await user.getIdTokenResult(true);
      const isAdmin = idTokenResult.claims.admin;
      if (isAdmin) {
        setCurrentUser(user);
        setError(null);
      } else {
        await signOut(auth);
        setError('Access denied. Your email is not whitelisted.');
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setError('An error occurred during sign-in.');
    }
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, error, loading, logout, signInWithGoogle }}
    >
      {children}
    </AuthContext.Provider>
  );
};
