import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { initialInterestOptions, STRINGS } from '../constants/strings';
import {
  InterestFormContent,
  InterestOption,
  useInterestFormSubmissions,
} from '../context/InterestFormSubmissionContext';

interface InterestFormProps {
  closeInterestForm: () => void;
  email: string;
}

const initialFormContent: InterestFormContent = {
  comments: '',
  email: '',
  interests: [],
};

const InterestForm: React.FC<InterestFormProps> = ({
  closeInterestForm,
  email,
}) => {
  const { addInterestFormSubmission } = useInterestFormSubmissions();
  const [interestFormContent, setInterestFormContent] =
    useState<InterestFormContent>(initialFormContent);
  const [customInterestTitle, setCustomInterestTitle] = useState<string>('');
  const [interestOptions, setInterestOptions] = useState<InterestOption[]>(
    initialInterestOptions,
  );
  const [isSubmittingInterestsForm, setIsSubmittingInterestsForm] =
    useState(false);
  const [customInterestError, setCustomInterestError] = useState('');

  useEffect(() => {
    setInterestFormContent((prev) => ({ ...prev, email }));
  }, [email]);

  useEffect(() => {
    if (!customInterestTitle) {
      setCustomInterestError('');
    }
  }, [customInterestTitle]);

  const handleInterestFormSubmit = async () => {
    setIsSubmittingInterestsForm(true);

    try {
      await addInterestFormSubmission(interestFormContent);
      toast.success(STRINGS.interestFormSuccessMessage);
      setInterestFormContent(initialFormContent);
      closeInterestForm();
    } catch (error) {
      toast.error(STRINGS.interestFormErrorMessage);
    }

    setIsSubmittingInterestsForm(false);
  };

  const handleToggleInterest = (interest: InterestOption) => {
    setInterestFormContent((prev) => ({
      ...prev,
      interests: prev.interests.find((i) => i.title === interest.title)
        ? prev.interests.filter((i) => i.title !== interest.title)
        : [...prev.interests, interest],
    }));
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterestFormContent((prev) => ({
      ...prev,
      comments: event.target.value,
    }));
  };

  const addCustomInterest = () => {
    const isFilled = Boolean(customInterestTitle);
    const isDuplicate = interestOptions
      .map((interest) => interest.title.toLowerCase())
      .includes(customInterestTitle.toLowerCase());

    if (!isFilled) {
      setCustomInterestError(STRINGS.requiredTitleError);
      return;
    }

    if (isDuplicate) {
      setCustomInterestError(STRINGS.duplicateInterestError);
      return;
    }

    const customInterest = {
      title: customInterestTitle,
      type: 'custom',
    };
    setInterestOptions((prev) => [...prev, customInterest]);
    handleToggleInterest(customInterest);
    setCustomInterestTitle('');
    setCustomInterestError('');
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', padding: 4 }}
      data-testid="interest-form"
    >
      <IconButton
        onClick={closeInterestForm}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h3" sx={{ mb: 2 }}>
        {STRINGS.interestFormTitle}
      </Typography>
      <Typography variant="body1">{STRINGS.interestFormDescription}</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {STRINGS.interestFormDescription2}
      </Typography>

      <Typography variant="h6" sx={{ mb: 1, mt: 3 }}>
        {STRINGS.interestFormInterestSelectLabel}
      </Typography>

      <FormControl component="fieldset" sx={{ mb: 3 }} variant="standard">
        <FormGroup>
          {interestOptions.map((interest) => (
            <FormControlLabel
              key={interest.title}
              control={
                <Checkbox
                  checked={Boolean(
                    interestFormContent.interests.find(
                      (i) => i.title === interest.title,
                    ),
                  )}
                  onChange={() => handleToggleInterest(interest)}
                  name={interest.title}
                />
              }
              label={interest.title}
            />
          ))}

          <Box sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
            <TextField
              label={STRINGS.interestFormCustomInterestLabel}
              placeholder={STRINGS.interestFormCustomInterestPlaceholder}
              variant="outlined"
              size="small"
              value={customInterestTitle}
              onChange={(e) => setCustomInterestTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addCustomInterest();
                }
              }}
              sx={{ flex: 1, mr: 1 }}
            />
            <Button
              variant="outlined"
              onClick={addCustomInterest}
              disabled={!customInterestTitle}
            >
              {STRINGS.interestFormAddInterestButtonText}
            </Button>
          </Box>
        </FormGroup>

        {customInterestError ? (
          <FormHelperText>{customInterestError}</FormHelperText>
        ) : (
          <FormHelperText>
            {STRINGS.interestFormInterestsHelperText}
          </FormHelperText>
        )}
      </FormControl>

      <TextField
        placeholder={STRINGS.interestFormCommentsPlaceholder}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={interestFormContent.comments}
        onChange={handleCommentChange}
        sx={{ mb: 2 }}
        label={STRINGS.interestFormCommentsLabel}
      />

      <LoadingButton
        loading={isSubmittingInterestsForm}
        endIcon={<SendIcon />}
        color="primary"
        loadingPosition="end"
        onClick={handleInterestFormSubmit}
        variant="contained"
        sx={{ alignSelf: 'center' }}
      >
        {STRINGS.formSubmitButtonText}
      </LoadingButton>
    </Box>
  );
};

export default InterestForm;
