import React from 'react';

import BuildIcon from '@mui/icons-material/Build';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeartIcon from '@mui/icons-material/Favorite';
import GroupIcon from '@mui/icons-material/Group';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

const steps = [
  {
    icon: GroupIcon,
    label: 'Become a member',
  },
  {
    icon: BuildIcon,
    label: 'Use our tools',
  },
  {
    icon: CelebrationIcon,
    label: 'Make cool things',
  },
  {
    icon: HeartIcon,
    label: 'Meet friends',
  },
];

const membershipInfoContent = [
  {
    items: [
      '24/hr workshop access',
      'Early sign up for classes',
      'More benefits...',
    ],
    title: 'Spark Monthly Membership provides',
  },
  {
    items: [
      'All previous benefits for less',
      'Fostering creativity',
      'Founding member party!',
    ],
    title: 'Spark Founding Membership',
  },
];

const ProcessSteps = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        margin: isSmallScreen ? '' : 'auto',
        maxWidth: 400,
        mb: 3,
        textAlign: isSmallScreen ? 'inherit' : 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        How it Works
      </Typography>
      <Stepper
        activeStep={-1}
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        alternativeLabel={!isSmallScreen}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            {/* Todo: update first item to have a primary color */}
            <StepLabel icon={React.createElement(step.icon)}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MembershipInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const renderList = (items: string[]) => (
    <Box
      component="ul"
      sx={{ '& li': { typography: 'body1' }, listStyleType: 'disc', ml: 3 }}
    >
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </Box>
  );

  return isMobile ? (
    <Box>
      {membershipInfoContent.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{renderList(section.items)}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
      {membershipInfoContent.map((section, index) => (
        <Card key={index} sx={{ width: 500 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {section.title}
            </Typography>
            {renderList(section.items)}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const HowItWorks = () => (
  <>
    <ProcessSteps />
    {/* <MembershipInfo /> */}
  </>
);

export default HowItWorks;
