import React from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import alexImage from '../assets/images/alex.jpg';
import amelieImage from '../assets/images/amelie.jpg';
import calebImage from '../assets/images/caleb.jpg';
import ireneImage from '../assets/images/irene.jpg';
import jessImage from '../assets/images/jess.jpg';
import joshImage from '../assets/images/josh.jpg';
import kateImage from '../assets/images/kate.jpg';
import tylerImage from '../assets/images/tyler.jpg';

interface TeamMember {
  description: string;
  imageUrl: string;
  name: string;
}

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  const teamMembers: TeamMember[] = [
    {
      description: `Building has always been my favorite way to express creativity. Throughout my life I've built furniture, sculptures, a camper van and endless DIY projects. While I am most familiar with woodworking, 3D printing, and coding I always let my projects bring me out of my comfort zone. To me this is what Brooklyn Spark is all about. A community of people who are eager to share and eager to learn!`,
      imageUrl: calebImage,
      name: 'Caleb',
    },
    {
      description: `My favorite thing to do is take things that were headed to the landfill and breathe new life into them. Whether through repair, art, or adaptive reuse, I want to help create a space where others are empowered to do this awesome work too and have fun along the way!`,
      imageUrl: tylerImage,
      name: 'Tyler',
    },
    {
      description: `A background in textiles and unending curiosity has me dreaming up projects time and time again. Weaving, sewing, designing and drafting are tools that I use to understand and contemplate our place in the world at large. I look forward to creating and discovering in community with you.`,
      imageUrl: kateImage,
      name: 'Kate',
    },
    {
      description: `I'm all about making things - especially when they're both functional and beautiful. Woodworking is my passion, and any spare moment I get, I'm either with my tools or diving into electronics or software. My favorite thing is to combine them all. I've found such an incredibly welcoming space in the maker community, and I'm excited to build something great together!`,
      imageUrl: amelieImage,
      name: 'Amelie',
    },
    {
      description: `I love dabbling in various crafts and art forms-- it's one of my favorite ways of bringing friends (and acquaintances!) together. I live for texture and textiles crafted by some of my favorite tools: knitting, latch hooking, punch needle, sewing just to name a few. Nothing sparks more delight for me than running my hands over a carefully constructed piece of textile. I'm excited to explore new crafts and mixing mediums with you.`,
      imageUrl: ireneImage,
      name: 'Irene',
    },
    {
      description: `In a past life, I worked in manufacturing & logistics. I love learning how to both make things and break things, and I love being surrounded by folks who sit on the fence between creative and technical.. so I'm excited to both build out our own little community, and plug ourselves into the larger communities of artists and makers here in Brooklyn!`,
      imageUrl: jessImage,
      name: 'Jess',
    },
    {
      description: `I am passionate about building things that are functional as well as things that are beautiful — and sometimes both. My specialty is in designing loudspeakers but I am curious to expand my skills to materials beyond wood as we build and grow our community of makers.`,
      imageUrl: alexImage,
      name: 'Alex',
    },
    {
      description: `I've always felt a drive to understand how things work and build my own solutions, whether its been furniture, electronics, or live events. I'm so excited to be part of this project to build a space and community to support like minded people.`,
      imageUrl: joshImage,
      name: 'Josh',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Meet the Team
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <CardMedia
                component="img"
                height="140"
                image={member.imageUrl}
                alt={member.name}
                sx={{
                  height: 380,
                  objectFit: 'cover',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {member.name}
                </Typography>

                <Typography variant="body1" color="text.primary">
                  {member.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default About;
