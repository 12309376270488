export function getFirebaseErrorMessage(errorCode: string): string {
  const errorMessages: Record<string, string> = {
    aborted: 'The operation was aborted due to an issue.',
    'already-exists': 'This already exists.',
    'auth/account-exists-with-different-credential':
      'An account exists with the same email address but different credentials.',
    'auth/admin-restricted-operation':
      'This operation is restricted to administrators only.',
    'auth/already-initialized':
      'initializeAuth() has already been called with different options. Call initializeAuth() with the same options or use getAuth() to return the initialized instance.',
    'auth/app-deleted': 'FirebaseApp has been deleted.',
    'auth/app-not-authorized':
      'This app is not authorized to use Firebase Authentication with the provided API key.',
    'auth/app-not-installed':
      'The requested mobile application is not installed on this device.',
    'auth/auth-domain-config-required':
      'Include authDomain when initializing Firebase.',
    'auth/cancelled-popup-request':
      'The operation has been cancelled due to another conflicting popup.',
    'auth/captcha-check-failed':
      'The reCAPTCHA response token is invalid or expired.',
    'auth/claims-too-large':
      'The custom claims payload exceeds the maximum allowed size.',
    'auth/code-expired': 'The SMS code has expired.',
    'auth/cordova-not-ready': 'Cordova framework is not ready.',
    'auth/cors-unsupported': 'This browser is not supported.',
    'auth/credential-already-in-use':
      'This credential is already associated with another user account.',
    'auth/custom-token-mismatch':
      'The custom token does not match the audience.',
    'auth/dependent-sdk-initialized-before-auth':
      'Another Firebase SDK was initialized before Auth.',
    'auth/dynamic-link-not-activated': 'Dynamic Links are not activated.',
    'auth/email-already-exists':
      'The provided email is already in use by another user.',
    'auth/email-already-in-use': 'The email address is already in use.',
    'auth/email-change-needs-verification':
      'Multi-factor users must have a verified email.',
    'auth/emulator-config-failed':
      'Auth instance has been used for a network call. Emulator configuration cannot be applied.',
    'auth/expired-action-code': 'The action code has expired.',
    'auth/id-token-expired': 'The Firebase ID token has expired.',
    'auth/id-token-revoked': 'The Firebase ID token has been revoked.',
    'auth/insufficient-permission':
      'Insufficient permission to access Authentication resource. Please generate a credential with appropriate permissions.',
    'auth/internal-error': 'An internal AuthError has occurred.',
    'auth/invalid-action-code': 'Invalid action code.',
    'auth/invalid-api-key': 'Invalid API key.',
    'auth/invalid-app-credential':
      'The phone verification request contains an invalid application verifier.',
    'auth/invalid-app-id':
      'The mobile app identifier is not registered for the current project.',
    'auth/invalid-argument':
      'Invalid argument provided to Authentication method.',
    'auth/invalid-auth-event': 'An internal AuthError has occurred.',
    'auth/invalid-cert-hash': 'Invalid SHA-1 certificate hash.',
    'auth/invalid-claims': 'Invalid custom claim attributes provided.',
    'auth/invalid-continue-uri':
      'The continue URL provided in the request is invalid.',
    'auth/invalid-cordova-configuration':
      'Cordova plugins must be installed for OAuth sign-in.',
    'auth/invalid-creation-time': 'Invalid creation time format.',
    'auth/invalid-credential': 'Invalid auth credential.',
    'auth/invalid-custom-token': 'Invalid custom token format.',
    'auth/invalid-disabled-field':
      'Invalid value for the disabled user property.',
    'auth/invalid-display-name':
      'Invalid value for the displayName user property.',
    'auth/invalid-dynamic-link-domain':
      'The dynamic link domain is not configured for the project.',
    'auth/invalid-email': 'Invalid email address.',
    'auth/invalid-email-verified':
      'Invalid value for the emailVerified user property.',
    'auth/invalid-emulator-scheme': 'Invalid emulator URL scheme.',
    'auth/invalid-hash-algorithm': 'Invalid hash algorithm provided.',
    'auth/invalid-hash-block-size': 'Invalid hash block size.',
    'auth/invalid-hash-derived-key-length': 'Invalid hash derived key length.',
    'auth/invalid-hash-key': 'Invalid hash key.',
    'auth/invalid-hash-memory-cost': 'Invalid hash memory cost.',
    'auth/invalid-hash-parallelization': 'Invalid hash parallelization.',
    'auth/invalid-hash-rounds': 'Invalid hash rounds.',
    'auth/invalid-hash-salt-separator':
      'Invalid hashing algorithm salt separator field.',
    'auth/invalid-id-token': 'Invalid Firebase ID token provided.',
    'auth/invalid-last-sign-in-time': 'Invalid last sign-in time format.',
    'auth/invalid-message-payload':
      'Invalid characters in email template message.',
    'auth/invalid-multi-factor-session':
      'No valid proof of first factor successful sign-in.',
    'auth/invalid-oauth-client-id': 'Invalid OAuth client ID.',
    'auth/invalid-oauth-provider':
      'EmailAuthProvider is not supported for this operation.',
    'auth/invalid-oauth-responsetype':
      'Only one OAuth responseType should be set to true.',
    'auth/invalid-page-token': 'Invalid next page token in listUsers().',
    'auth/invalid-password': 'Invalid password format.',
    'auth/invalid-password-hash': 'Invalid password hash.',
    'auth/invalid-password-salt': 'Invalid password salt.',
    'auth/invalid-persistence-type': 'Invalid persistence type.',
    'auth/invalid-phone-number': 'Invalid phone number format.',
    'auth/invalid-photo-url': 'Invalid value for the photoURL user property.',
    'auth/invalid-provider-data': 'Invalid providerData array.',
    'auth/invalid-provider-id': 'Invalid provider ID.',
    'auth/invalid-recipient-email': 'Invalid recipient email address.',
    'auth/invalid-sender': 'Invalid sender email or name in email template.',
    'auth/invalid-session-cookie-duration': 'Invalid session cookie duration.',
    'auth/invalid-tenant-id': 'Invalid tenant ID.',
    'auth/invalid-uid': 'Invalid uid format.',
    'auth/invalid-user-import': 'Invalid user record to import.',
    'auth/invalid-user-token':
      "The user's credential is not valid for this project.",
    'auth/invalid-verification-code': 'The SMS verification code is invalid.',
    'auth/invalid-verification-id': 'Invalid verification ID.',
    'auth/maximum-second-factor-count-exceeded':
      'Maximum allowed number of second factors exceeded.',
    'auth/maximum-user-count-exceeded':
      'Maximum allowed number of users to import exceeded.',
    'auth/missing-android-pkg-name': 'Android Package Name must be provided.',
    'auth/missing-app-credential':
      'Phone verification request is missing an application verifier assertion.',
    'auth/missing-continue-uri':
      'A continue URL must be provided in the request.',
    'auth/missing-hash-algorithm':
      'Hashing algorithm and parameters required for password hashes.',
    'auth/missing-iframe-start': 'An internal AuthError has occurred.',
    'auth/missing-ios-bundle-id':
      'iOS Bundle ID must be provided if App Store ID is provided.',
    'auth/missing-multi-factor-info': 'No second factor identifier provided.',
    'auth/missing-multi-factor-session':
      'Proof of first factor successful sign-in missing.',
    'auth/missing-oauth-client-secret':
      'OAuth configuration client secret is required for OIDC code flow.',
    'auth/missing-or-invalid-nonce': 'Invalid nonce in request.',
    'auth/missing-phone-number': 'Provide a phone number for verification.',
    'auth/missing-uid': 'Uid identifier required for the current operation.',
    'auth/missing-verification-code':
      'Phone auth credential was created with an empty SMS verification code.',
    'auth/missing-verification-id':
      'Phone auth credential was created with an empty verification ID.',
    'auth/multi-factor-auth-required':
      'Proof of ownership of a second factor required.',
    'auth/multi-factor-info-not-found': 'No matching second factor found.',
    'auth/network-request-failed': 'Network AuthError has occurred.',
    'auth/no-auth-event': 'An internal AuthError has occurred.',
    'auth/no-such-provider': 'No user linked to the given provider.',
    'auth/null-user': 'Null user object provided.',
    'auth/operation-not-allowed':
      'Sign-in provider is disabled for this project.',
    'auth/operation-not-supported-in-this-environment':
      'Operation not supported in this environment.',
    'auth/phone-number-already-exists':
      'The provided phoneNumber is already in use by another user.',
    'auth/popup-blocked':
      'Popup connection establishment blocked by the browser.',
    'auth/popup-closed-by-user':
      'Popup closed by user before operation completion.',
    'auth/project-not-found':
      'No Firebase project found for the credential used to initialize the Admin SDKs.',
    'auth/provider-already-linked': 'User already linked to another identity.',
    'auth/quota-exceeded':
      "Project's quota for this operation has been exceeded.",
    'auth/redirect-cancelled-by-user': 'Redirect operation cancelled by user.',
    'auth/redirect-operation-pending':
      'Redirect sign-in operation is already pending.',
    'auth/rejected-credential':
      'Request contains malformed or mismatching credentials.',
    'auth/requires-recent-login':
      'This operation requires recent authentication.',
    'auth/reserved-claims':
      'Custom user claims should not use reserved claim keys.',
    'auth/second-factor-already-in-use':
      'Second factor is already enrolled on this account.',
    'auth/session-cookie-expired': 'The Firebase session cookie has expired.',
    'auth/session-cookie-revoked':
      'The Firebase session cookie has been revoked.',
    'auth/tenant-id-mismatch':
      "Tenant ID does not match Auth instance's tenant ID",
    'auth/timeout': 'Operation has timed out.',
    'auth/too-many-requests':
      'Requests from this device are blocked due to unusual activity. Try again later.',
    'auth/uid-already-exists':
      'The provided uid is already in use by another user.',
    'auth/unauthorized-continue-uri':
      'Domain of continue URL is not whitelisted.',
    'auth/unauthorized-domain': 'Domain not authorized for OAuth operations.',
    'auth/unsupported-first-factor':
      'Enrolling second factor or signing in with multi-factor account requires supported first factor.',
    'auth/unsupported-persistence-type':
      'Current environment does not support specified persistence type.',
    'auth/unsupported-tenant-operation':
      'Operation not supported in a multi-tenant context.',
    'auth/unverified-email': 'Operation requires verified email.',
    'auth/user-cancelled': 'User did not grant requested permissions.',
    'auth/user-disabled': 'User account disabled by administrator.',
    'auth/user-mismatch':
      'Supplied credentials do not match previously signed-in user.',
    'auth/user-not-found': 'User record not found.',
    'auth/user-token-expired':
      "User's credential is no longer valid. User must sign in again.",
    'auth/weak-password': 'Password must be at least 6 characters long.',
    'auth/web-storage-unsupported':
      'This browser is not supported or 3rd party cookies and data may be disabled.',
    unauthenticated: 'Authentication credentials are missing or invalid.',
    unavailable: 'Service currently unavailable. Please retry later.',
    unimplemented: 'This feature is not available at the moment.',
    unknown: 'Something went wrong on our end. Try again later.',
  };

  return (
    errorMessages[errorCode] || 'An error occurred. Please try again later.'
  );
}
